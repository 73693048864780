import { DatePicker, Modal, Switch, Tooltip, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAppointment } from "../../../redux/actions/appointments/addAppointment";
import {
  createPatient,
  createPatientToggle,
  fileNo,
  getUrl,
} from "../../../redux/actions/appointments/createPatient";
import {
  copyButton,
  getPatientAppointmet,
  getPatientModified,
  resetGetPatient,
  singlePatientToggle,
} from "../../../redux/actions/appointments/getPatient";
import { resetPatientSearchAdv } from "../../../redux/actions/appointments/patientAdvSearch";
import { savePatient } from "../../../redux/actions/appointments/savePatient";
import { resetSearchPatient } from "../../../redux/actions/appointments/searchPatient";
import { setPartner } from "../../../redux/actions/appointments/setPartner";
import Button from "../../../UIComponents/Button/Button";
import {
  default as Input,
  default as InputField,
} from "../../../UIComponents/Input/Input";
import NumberButton from "../../../UIComponents/NumberButton/NumberButton";
import SingleSelect from "../../../UIComponents/Select/SingleSelect";
import { toast } from "../../../UIComponents/Toast";
import "./NewPatient.css";

const stateData = [
  {
    id: "512",
    code: "AN",
    name: "Andaman Nicobar",
  },
  {
    id: "513",
    code: "AP",
    name: "Andhra Pradesh",
  },
  {
    id: "514",
    code: "AR",
    name: "Arunachal Pradesh",
  },
  {
    id: "515",
    code: "AS",
    name: "Assam",
  },
  {
    id: "516",
    code: "BH",
    name: "Bihar",
  },
  {
    id: "517",
    code: "CH",
    name: "Chandigarh",
  },
  {
    id: "518",
    code: "CG",
    name: "Chhattisgarh",
  },
  {
    id: "519",
    code: "DN",
    name: "Dadra Nagar Haveli",
  },
  {
    id: "520",
    code: "DD",
    name: "Daman Diu",
  },
  {
    id: "521",
    code: "DL",
    name: "Delhi",
  },
  {
    id: "522",
    code: "GA",
    name: "Goa",
  },
  {
    id: "523",
    code: "GJ",
    name: "Gujarat",
  },
  {
    id: "524",
    code: "HR",
    name: "Haryana",
  },
  {
    id: "525",
    code: "HP",
    name: "Himachal Pradesh",
  },
  {
    id: "526",
    code: "JK",
    name: "Jammu Kashmir",
  },
  {
    id: "527",
    code: "JH",
    name: "Jharkhand",
  },
  {
    id: "528",
    code: "KA",
    name: "Karnataka",
  },
  {
    id: "529",
    code: "KL",
    name: "Kerala",
  },
  {
    id: "530",
    code: "LD",
    name: "Lakshadweep",
  },
  {
    id: "531",
    code: "MP",
    name: "Madhya Pradesh",
  },
  {
    id: "532",
    code: "MH",
    name: "Maharashtra",
  },
  {
    id: "533",
    code: "MN",
    name: "Manipur",
  },
  {
    id: "534",
    code: "ML",
    name: "Meghalaya",
  },
  {
    id: "535",
    code: "MZ",
    name: "Mizoram",
  },
  {
    id: "536",
    code: "NL",
    name: "Nagaland",
  },
  {
    id: "537",
    code: "OR",
    name: "Odisha",
  },
  {
    id: "538",
    code: "PY",
    name: "Pondicherry",
  },
  {
    id: "539",
    code: "PB",
    name: "Punjab",
  },
  {
    id: "540",
    code: "RJ",
    name: "Rajasthan",
  },
  {
    id: "541",
    code: "SK",
    name: "Sikkim",
  },
  {
    id: "542",
    code: "TN",
    name: "Tamil Nadu",
  },
  {
    id: "543",
    code: "TG",
    name: "Telangana",
  },
  {
    id: "544",
    code: "TR",
    name: "Tripura",
  },
  {
    id: "545",
    code: "UP",
    name: "Uttar Pradesh",
  },
  {
    id: "546",
    code: "UT",
    name: "Uttarakhand",
  },
  {
    id: "547",
    code: "WB",
    name: "West Bengal",
  },
];
const data = [
  {
    id: "phone",
    name: "phone",
  },
  {
    id: "apollo",
    name: "apollo",
  },
];
const walkinData = [
  {
    id: "W",
    name: "W",
  },
  {
    id: "A",
    name: "A",
  },
  {
    id: "O",
    name: "O",
  },
];
const CityData = [
  {
    id: "Banglore",
    name: "Banglore",
  },
  {
    id: "Mysore",
    name: "Mysore",
  },
  {
    id: "Mandya",
    name: "Mandya",
  },
  {
    id: "Maddur",
    name: "Maddur",
  },
];
const pincodeData = [
  {
    id: "91",
    name: "91",
  },
  {
    id: "61",
    name: "61",
  },
  {
    id: "81",
    name: "81",
  },
  {
    id: "31",
    name: "31",
  },
];
const genderdata = [
  {
    id: "M",
    name: "M",
  },
  {
    id: "F",
    name: "F",
  },
  {
    id: "NA",
    name: "NA",
  },
];
const mrSelectData = [
  {
    id: "Mr",
    name: "Mr",
  },
  {
    id: "Mrs",
    name: "Mrs",
  },
  {
    id: "Mx",
    name: "Mx",
  },
  {
    id: "Ms",
    name: "Ms",
  },
  {
    id: "Miss",
    name: "Miss",
  },
  {
    id: "Master",
    name: "Master",
  },
  {
    id: "Baby",
    name: "Baby",
  },
  {
    id: "Dr",
    name: "Dr",
  },
  {
    id: "B/O",
    name: "B/O",
  },
];
const idData = [
  {
    id: "Adhaar",
    name: "Adhaar",
  },
  {
    id: "Drivers Licence",
    name: "Drivers Licence",
  },
  {
    id: "PAN",
    name: "PAN",
  },
  {
    id: "Passport",
    name: "Passport",
  },
  {
    id: "Voter ID",
    name: "Voter ID",
  },
];

const bloodgroupdata = [
  {
    id: "A+",
    name: "A+",
  },
  {
    id: "A-",
    name: "A-",
  },
  {
    id: "B+",
    name: "B+",
  },
  {
    id: "B-",
    name: "B-",
  },
  {
    id: "O+",
    name: "O+",
  },
  {
    id: "O-",
    name: "O-",
  },
  {
    id: "AB+",
    name: "AB+",
  },
  {
    id: "AB-",
    name: "AB-",
  },
];
const guardianData = [
  {
    id: "b/o",
    name: "b/o",
  },
  {
    id: "c/o",
    name: "c/o",
  },
  {
    id: "w/o",
    name: "w/o",
  },
  {
    id: "s/o",
    name: "s/o",
  },
  {
    id: "d/o",
    name: "d/o",
  },
  {
    id: "h/o",
    name: "hs/o",
  },
];
const NewPatient = () => {
  const imageUrl = useSelector(
    (state) => state.appointments?.createPatient.ImageUrl
  );
  console.log("urlll", imageUrl);

  const singleToggles = useSelector(
    (state) => state.appointments?.getPatient.singleToggle
  );
  console.log("singleToggles", singleToggles);

  const FileNo = useSelector(
    (state) => state.appointments?.createPatient.fileNo.fileNo
  );
  console.log("fileno", FileNo);

  const getPatientModifiedData = useSelector(
    (state) => state.appointments.getPatient.getPatientModifiedData
  );
  console.log("getPatientModifiedData", getPatientModifiedData);

  const setPatientData = useSelector((state) => state.appointments);
  console.log("setPatientData", setPatientData);

  const profileInfo = useSelector(
    (state) => state.profile?.profileInfo.locations
  );
  console.log("profileInfo", profileInfo);

  const getAppointment = useSelector(
    (state) => state.appointments?.getPatient.searchPatient
  );
  console.log("getapp", getAppointment);

  const patientToggle = useSelector(
    (state) => state.opdrecord?.patientreport.patientToggle
  );
  console.log("patientToggle", patientToggle);

  const copyToggle = useSelector(
    (state) => state.appointments.getPatient.copyButtonToggle
  );
  console.log("copyButton", copyToggle);

  const getSinglePatientData = useSelector(
    (state) => state.appointments.getPatient.getPatient
  );
  console.log("getSinglePatientData", getSinglePatientData);
  const error11 = useSelector(
    (state) => state.appointments.createPatient.error
  );
  console.log("dsfvsdf", error11);

  const newPatientToggle = useSelector(
    (state) => state.appointments.createPatient.createPatientToggle
  );
  console.log("newPatientToggle", newPatientToggle);

  const clinicName = useSelector((state) => state.profile.clinicLocations);
  console.log("clinicName", clinicName);
  const createPatientDetails = useSelector(
    (state) => state.appointments?.createPatient.createPatient
  );

  const doctorInfo = useSelector((state) => state.profile.profileInfo);
  console.log("doctorInfo", doctorInfo);
  const getStatusCode = useSelector(
    (state) => state.appointments.createPatient.getStatusCode
  );

  const loginInfo = useSelector((state) => state.login.loginInfo);
  console.log("login info", loginInfo);
  const clinicId = useSelector((state) => state.profile.clinicId);
  console.log("clinicId", clinicId);

  const [dob, setDob] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [employeeId, setmployeeId] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");
  const [city, setCity] = useState("");
  const [patientId, setpatientId] = useState("");
  const [country, setCountry] = useState("India");
  const [uniqueno, setUniqueno] = useState("");
  const [gender, setGender] = useState("");
  const [salutation, setSalutation] = useState("");
  const [guardian, setGuardian] = useState("");
  const [relationship, setRelationship] = useState("");
  const [emailid, setEmailid] = useState("");
  const [idno, setIdno] = useState("");
  const [idtype, setIdtype] = useState("");
  const [bloodgroup, setBloodgroup] = useState("");
  const [walkin, setWalkin] = useState(
    walkinData.length ? walkinData[0].id : ""
  );
  const [scheduledTime, setScheduledTime] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [timeslot, setTimeslot] = useState("");
  const [note, setNote] = useState("");
  const [age, setAge] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [buttonapi, setButtonApi] = useState(false);
  const [locationId, setLocationId] = useState(
    clinicName?.length === 0
      ? doctorInfo?.locations?.[0]?.id
      : clinicName[0]?.id
  );
  const [useAlternate, setUseAlternate] = useState(false);
  const [getSingleSlot, setGetSingleSlots] = useState("");
  const [slotTimings, setSlotTimings] = useState([]);
  const [altSwitch, setAltSwitch] = useState(false);
  const [comments, setComments] = useState("");
  const [idInputLength, setIdInputLength] = useState();

  const dispatch = useDispatch();
  //tooltip
  const dobs = <span>Format: DD/MM/YYYY</span>;
  const doctorId = loginInfo.id;
  console.log("renknrnnkr", doctorId);
  const doctorName = loginInfo.name;
  const PatientId = createPatientDetails?.id;
  const statuscode = getStatusCode.toString();
  const addAppointmentData = {
    aptType: walkin,
    patientId: PatientId,
    doctorId: doctorId,
    locationId: locationId,
    scheduledTime: scheduledTime,
    timeslot: timeslot,
    lastUpdatedBy: doctorName,
    department: null,
    doctorIncharge: null,
    invoiceTemplate: null,
    note: note,
  };

  useEffect(() => {
    if (clinicName.length) {
      setLocationId(clinicName?.[0]?.id);
    }
  }, [clinicName]);

  console.log("ksdjkdkdsdd", FileNo);
  const apidata = {
    name: name,
    patientId: patientId,
    mobile: mobile,
    employeeId: employeeId,
    address: {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      country: country,
    },
    dob: dob,
    gender: gender,
    useAlternate: altSwitch,
    alternatePhone: alternatePhone,
    age: isNaN(age) ? " " : age,
    salutation: salutation,
    guardian: guardian,
    relationship: relationship,
    emailid: emailid,
    idno: idno,
    idtype: idtype,
    bloodgroup: bloodgroup,
    rating: { [doctorId]: comments },
  };

  console.log("kdsjfkjsfkjslfskj", apidata);

  const apidatas = {
    name: name,
    mobile: mobile,
    employeeId: employeeId,
    alternatePhone: alternatePhone,
    address: {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      country: country,
    },
    dob: dob,
    age: isNaN(age) ? " " : age,
    gender: gender,
    profileImage: imageUrl?.image ? imageUrl?.image : imageUrl?.photo,
    useAlternate: altSwitch,
    alternatePhone: useAlternate,
    salutation: salutation,
    guardian: guardian,
    relationship: relationship,
    emailid: emailid,
    idno: idno,
    idtype: idtype,
    bloodgroup: bloodgroup,
    doctorId: doctorId,
    rating: { [doctorId]: comments },
  };

  const savePatientApiData = {
    name: name,
    patientId: patientId,
    mobile: mobile,
    employeeId: employeeId,
    address: {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      pincode: pincode,
      country: country,
    },
    dob: dob,
    gender: gender,
    profileImage: imageUrl?.image ? imageUrl?.image : imageUrl?.photo,
    useAlternate: altSwitch,
    alternatePhone: alternatePhone,
    salutation: salutation,
    age: isNaN(age) ? " " : age,
    guardian: guardian,
    relationship: relationship,
    emailid: emailid,
    idno: idno,
    idtype: idtype,
    bloodgroup: bloodgroup,
    rating: { [doctorId]: comments },
  };
  const copyApiData = {
    for: patientId,
    who: createPatientDetails.id,
  };

  useEffect(() => {
    const getSlots = profileInfo?.filter((item) => item.id === locationId);
    console.log("getSlots", getSlots);
    setGetSingleSlots(getSlots);
  }, [locationId]);

  const getUniqueNo = (uni, docId) => {
    const doctorKeys = Object.keys(uni);
    console.log("doctorKeys", doctorKeys);
    const hasDocId = doctorKeys.includes(docId);
    console.log("hasDocId", hasDocId);
    if (hasDocId) return uni[docId];
    else if (typeof uni === "number") return uni;
    else return "";
  };

  useEffect(() => {
    if (getSinglePatientData && getPatientModifiedData === "editProfile") {
      console.log("updated data", getSinglePatientData);
      let {
        salutation,
        dob,
        mobile,
        employeeId,
        alternatePhone,
        gender,
        id,
        address,
        guardian,
        emailid,
        idtype,
        idno,
        bloodgroup,
        relationship,
        rating,
        uniqueno,
        age,
        useAlternate,
      } = getSinglePatientData;
      console.log("useAlternate", getSinglePatientData);
      const uniqueNo = getUniqueNo(uniqueno, doctorId);
      setSalutation(salutation);
      setName(getSinglePatientData?.name);
      setDob(dob);
      setMobile(mobile);
      setmployeeId(employeeId);
      setAlternatePhone(alternatePhone);
      setGender(gender);
      setpatientId(id);
      setCity(address?.city);
      setPincode(address?.pincode);
      setGuardian(guardian);
      setEmailid(emailid);
      setIdtype(idtype);
      setIdno(idno);
      setAddress1(address?.address1);
      setAddress2(address?.address2);
      setState(address?.state);
      setBloodgroup(bloodgroup);
      ageCal(age);
      setRelationship(relationship);
      setUniqueno(uniqueNo);
      setComments(rating?.[doctorId]);
      setAltSwitch(getSinglePatientData?.useAlternate);
    }
  }, [getSinglePatientData, getPatientModifiedData]);

  console.log("after useEffect", age);
  useEffect(() => {
    if (getSinglePatientData && getPatientModifiedData === "copyProfile") {
      console.log("inside copy profile");
      setName("");
      const uniqueNo = getUniqueNo(uniqueno, doctorId);
      setUniqueno(uniqueNo);
      setGuardian(getSinglePatientData?.name);
      setSalutation(getSinglePatientData.salutation);
      setDob(getSinglePatientData?.dob);
      setMobile(getSinglePatientData?.mobile);
      setmployeeId(getSinglePatientData?.employeeId);
      setAlternatePhone(getSinglePatientData?.alternatePhone);
      setGender(getSinglePatientData?.gender);
      setpatientId(getSinglePatientData?.id);
      setCity(getSinglePatientData?.address?.city);
      setPincode(getSinglePatientData?.address?.pincode);
      setEmailid(getSinglePatientData?.emailid);
      setIdtype(getSinglePatientData?.idtype);
      setIdno(getSinglePatientData?.idno);
      setAddress1(getSinglePatientData?.address?.address1);
      setAddress2(getSinglePatientData?.address?.address2);
      setState(getSinglePatientData?.address?.state);
      setBloodgroup(getSinglePatientData?.bloodgroup);
      ageCal();
      setRelationship(getSinglePatientData?.relationship);
      setComments(getSinglePatientData?.rating?.[doctorId]);
      setAltSwitch(getSinglePatientData?.useAlternate);
    }
  }, [getSinglePatientData, getPatientModifiedData]);
  useEffect(() => {
    if (buttonapi && statuscode === "201") {
      setCreateModal(true);
      dispatch(createPatientToggle(true));
      console.log("buttonapi", buttonapi, statuscode);
    }
  }, [buttonapi, statuscode]);

  useEffect(() => {
    if (buttonapi && statuscode === "201") {
      setCreateModal(true);
      dispatch(createPatientToggle(true));
      console.log("buttonapi", buttonapi, statuscode);
    }
  }, [buttonapi, statuscode]);

  const onDateTimeHandle = (value, dateString) => {
    const scheduledTimess = moment(value).format("YYYY-MM-DD");
    console.log("hhhhh", value);
    setScheduledTime(scheduledTimess);
    setTimeslot("");
  };

  useEffect(() => {
    if ((scheduledTime, clinicName, doctorInfo)) {
      console.log("sgfsfgsgs", scheduledTime, clinicName);
      const getDay = moment(scheduledTime).format("dddd");
      console.log("values in calendar", getDay);
      if (getDay && clinicName && doctorInfo) {
        const getAllTimings = doctorInfo?.locations?.filter(
          (item) => item.id === clinicId
        );
        console.log("getAllTimings", getAllTimings);
        const timings =
          getAllTimings?.length === 0
            ? doctorInfo?.locations?.length &&
              doctorInfo?.locations?.[0]?.timings?.filter(
                (item) => item.day === getDay
              )[0]?.slots
            : getAllTimings?.[0]?.timings?.filter(
                (item) => item.day === getDay
              )[0]?.slots;
        let slots = timings?.map((arr) => {
          console.log(
            "inside map function",
            `${scheduledTime} ${arr}`,
            timings,
            getAllTimings
          );
          const selectedTime = `${scheduledTime} ${arr}`;
          return {
            name: arr,
            disabled: moment().isAfter(
              moment(selectedTime, "YYYY-MM-DD hh:mm A")
            ),
          };
        });
        console.log("timings", slots);
        setSlotTimings(slots);
      }
    }
  }, [scheduledTime, clinicName, doctorInfo]);
  const handleOk = () => {
    if (!walkin || !scheduledTime) {
      toast.error("please enter all the input fields");
    } else if (!timeslot) {
      toast.error("please select time slot");
    } else {
      dispatch(addAppointment(addAppointmentData));
      setCreateModal(false);
      setButtonApi(false);
      setTimeslot("");
      setNote("");
      setName("");
      setDob("");
      setMobile("");
      setAddress1("");
      setAddress2("");
      setState("");
      setPincode("");
      setAlternatePhone("");
      setCity("");
      setGender("");
      setSalutation("");
      setGuardian("");
      setRelationship("");
      setEmailid("");
      setIdno("");
      setIdtype("");
      setBloodgroup("");
      setAge("");
      setUniqueno("");
      setComments("");
    }
  };

  const handleCancel = () => {
    setCreateModal(false);
    dispatch(createPatientToggle(false));
    setTimeslot("");
    setButtonApi(false);
  };

  const onSalutationHandle = (value) => {
    setSalutation(value.toString());
  };

  const onNameHandle = (e) => {
    setName(e.target.value.replace(/[^A-Za-z ]/gi, ""));
    if (e.target.value.length === 0) {
      toast.error("Please Enter Name");
    }
  };

  const onDobHandle = (e) => {
    console.log("ejnmkne", e);
    const dateValue = moment(e).format("DD/MM/YYYY");
    setDob(dateValue);
    const date = moment().format("YYYY").toString();
    const ageCalc = date - dateValue.substring(6, 10);
    setAge(ageCalc.toString());
  };

  const ageCal = (age) => {
    if (age) {
      const [value, type] = age.split(" ");
      switch (type) {
        case "Wk":
          if (value > 4) {
            const month = parseInt(moment.duration(value, "week").as("months"));
            setAge(month + " Months");
          } else return setAge(value + " Weeks");
          break;
        case "Mth":
          if (value > 12) {
            const year = parseInt(moment.duration(value, "month").as("years"));
            setAge(year + " Years");
          } else return setAge(value + " Months");
          break;
        case "Yr":
          return setAge(value + " Years");
          break;
      }
    } else return "";
  };

  const onWalkHandle = (value) => {
    setWalkin(value);
  };
  const onMobileHandle = (e) => {
    setMobile(e.target.value);
  };

  const onMobileAltHandle = (e) => {
    setAlternatePhone(e.target.value);
  };

  const onAddress1Handle = (e) => {
    setAddress1(e.target.value);
  };

  const onAddress2Handle = (e) => {
    setAddress2(e.target.value);
  };

  const onStateHandle = (value) => {
    setState(value.toString());
  };

  const onCityHandle = (e) => {
    setCity(e.target.value.replace(/[^A-Za-z ]/gi, ""));
  };

  const onPincodeHandle = (e) => {
    setPincode(e.target.value);
  };

  const onGuardianHandle = (e) => {
    setGuardian(e.target.value.replace(/[^A-Za-z ]/gi, ""));
  };
  const onGuardianRelaHandle = (value) => {
    setRelationship(value.toString());
  };
  const onEmailHandle = (e) => {
    setEmailid(e.target.value);
  };
  const onIdTypeHandle = (value) => {
    setIdtype(value.toString());
    switch (value) {
      case "Adhaar":
        setIdInputLength(12);
        break;
      case "Drivers Licence":
        setIdInputLength(16);
        break;
      case "PAN":
        setIdInputLength(10);
        break;
      case "Passport":
        setIdInputLength(9);
        break;
      case "Voter ID":
        setIdInputLength(10);
        break;
      default:
        return;
    }
    setIdno("");
  };
  const onIdHandle = (e) => {
    const { value } = e.target;
    console.log("valuefddfd", e.target.value);
    if (idtype) {
      const regex = idtype === "Adhaar" ? /^[0-9]*$/ : /^[A-Za-z0-9_-]*$/;
      // idtype === "Drivers Licence" ? /^[A-Za-z0-9_-]*$/ : idtype === "PAN" ? /^[A-Za-z0-9_-]*$/ : idtype === "Passport" ? /^[A-Za-z0-9_-]*$/ : ''
      console.log("regex", regex);
      // e.target.value = e.target.value.replace(/[^\w]|_/g, '').toLowerCase()
      if (regex?.test(e.target.value)) {
        console.log("inside iffffffffffffff");
        return setIdno(value);
      }
    }
  };

  console.log("idnoidno", idno);
  const handleTimeSlot = (value) => {
    setTimeslot(value);
  };

  const onNoteChange = (e) => {
    setNote(e.target.value);
  };
  const onBloodHandle = (value) => {
    setBloodgroup(value);
  };

  console.log("id type", idtype);

  const onGenderHandle = (value) => {
    setGender(value);
  };

  const onSwitchHandle = (e) => {
    setAltSwitch(e);
    setAlternatePhone("");
  };

  const onCommentsHandle = (e) => {
    setComments(e.target.value);
  };

  const onSaveHandle = () => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log("inside the on save handle", altSwitch, apidata, apidatas);
    if (!salutation) {
      toast.error("Please enter the salutation");
    } else if (!name) {
      toast.error("Please enter the name");
    } else if (!gender) {
      toast.error("Please enter the gender");
    } else if (!relationship) {
      toast.error("Please enter the relationship");
    } else if (!guardian) {
      toast.error("Please enter the guardian");
    } else if (!mobile) {
      toast.error("Please enter the mobile");
    } else if (!employeeId) {
      toast.error("Please enter the employee");
    } else if (mobile.length < 10) {
      toast.error("Mobile No. should be 10");
    } else if (!dob) {
      toast.error("Please enter the dob");
    }
    // else if (!bloodgroup) {
    //     toast.error("Please enter the blood group")
    // }
    // else if (!emailid) {
    //     toast.error("Please enter the email id")
    // }
    else if (emailid && !regex.test(emailid)) {
      toast.error("Please enter valid Email Id");
    }
    // else if (!emailid.includes('@')) {
    //     toast.error("Please enter the valid email id")
    // }
    // else if (!idtype) {
    //   toast.error("Please enter the id type");
    // } else if (!idno) {
    //   toast.error("Please enter the id no");
    // }
    else if (!address1) {
      toast.error("Please enter the address1");
    }
    //  else if (!address2) {
    //   toast.error("Please enter the address2");
    // }
    else if (!state) {
      toast.error("Please enter the state");
    } else if (!city) {
      toast.error("Please enter the city");
    } else if (!pincode) {
      toast.error("Please enter the pincode");
    } else if (pincode.length < 6) {
      toast.error("Pincode should be 6 digits");
    } else if (!country) {
      toast.error("Please enter the country");
    } else if (altSwitch && !alternatePhone) {
      toast.error("Please enter alternate number");
    } else if (altSwitch && alternatePhone?.length < 10) {
      toast.error("Alternate number should be 10 digits");
    } else if (imageUrl) {
      dispatch(fileNo({ doctorId, afterApiData: apidatas }));
      setButtonApi(true);
      dispatch(singlePatientToggle(false));
      dispatch(resetGetPatient());
      dispatch(resetSearchPatient());
      dispatch(resetPatientSearchAdv());
      dispatch(getUrl({ image: "" }));
      dispatch(getUrl({ photo: "" }));
    }
    // else if (altSwitch) {
    //     console.log({ ...apidata, useAlternate: true }, "hsadfjhfdkjadfkj")
    // }
    else {
      dispatch(fileNo({ doctorId, afterApiData: apidata }));
      setButtonApi(true);
      dispatch(singlePatientToggle(false));
      dispatch(resetGetPatient());
      dispatch(resetSearchPatient());
      dispatch(resetPatientSearchAdv());
      dispatch(getUrl({ image: "" }));
      dispatch(getUrl({ photo: "" }));
      setName("");
      setDob("");
      setMobile("");
      setAddress1("");
      setAddress2("");
      setState("");
      setPincode("");
      setAlternatePhone("");
      setCity("");
      setGender("");
      setSalutation("");
      setGuardian("");
      setRelationship("");
      setEmailid("");
      setIdno("");
      setIdtype("");
      setBloodgroup("");
      setAge("");
      setUniqueno("");
      setComments("");
    }
  };

  if (!error11) {
    setName("");
    setDob("");
    setMobile("");
    setAddress1("");
    setAddress2("");
    setState("");
    setPincode("");
    setAlternatePhone("");
    setCity("");
    setGender("");
    setSalutation("");
    setGuardian("");
    setRelationship("");
    setEmailid("");
    setIdno("");
    setIdtype("");
    setBloodgroup("");
    setAge("");
    setUniqueno("");
    setComments("");
  }

  const oneEditSaveHandle = () => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!salutation) {
      toast.error("Please enter the salutation");
    } else if (!name) {
      toast.error("Please enter the name");
    } else if (!gender) {
      toast.error("Please enter the gender");
    } else if (!relationship) {
      toast.error("Please enter the relationship");
    } else if (!guardian) {
      toast.error("Please enter the guardian");
    } else if (!mobile) {
      toast.error("Please enter the mobile");
    } else if (!employeeId) {
      toast.error("Please enter the employee");
    } else if (mobile.length < 10) {
      toast.error("Mobile No. should be 10");
    } else if (!dob) {
      toast.error("Please enter the dob");
    }
    // else if (!bloodgroup) {
    //   toast.error("Please enter the blood group");
    // }
    // else if (!emailid) {
    //   toast.error("Please enter the email id");
    // }
    // else if (!emailid.includes('@')) {
    //     toast.error("Please enter the valid email id")
    // }
    else if (emailid && regex.test(emailid === false)) {
      toast.error("Please enter valid Email Id");
    }
    // else if (!idtype) {
    //   toast.error("Please enter the id type");
    // } else if (!idno) {
    //   toast.error("Please enter the id no");
    // }
    else if (!address1) {
      toast.error("Please enter the address1");
    }
    // else if (!address2) {
    //   toast.error("Please enter the address2");
    // }
    else if (!state) {
      toast.error("Please enter the state");
    } else if (!city) {
      toast.error("Please enter the city");
    } else if (!pincode) {
      toast.error("Please enter the pincode");
    } else if (pincode.length < 6) {
      toast.error("Pincode should be 6 digits");
    } else if (!country) {
      toast.error("Please enter the country");
    } else if (altSwitch && alternatePhone.length < 10) {
      toast.error("Alternate number should be 10");
    } else {
      dispatch(savePatient(savePatientApiData, patientId));
      {
        console.log("iojdijijikjmd", patientId);
      }
      dispatch(singlePatientToggle(false));
      dispatch(resetGetPatient());
      dispatch(resetSearchPatient());
      dispatch(resetPatientSearchAdv());
      dispatch(getUrl({ image: "" }));
      dispatch(getUrl({ photo: "" }));
      setName("");
      setDob("");
      setMobile("");
      setAddress1("");
      setAddress2("");
      setState("");
      setPincode("");
      setAlternatePhone("");
      setCity("");
      setGender("");
      setSalutation("");
      setGuardian("");
      setRelationship("");
      setEmailid("");
      setIdno("");
      setIdtype("");
      setBloodgroup("");
      setAge("");
      setUniqueno("");
      setComments("");
      setComments("");
    }
  };

  const onClearHandle = () => {
    setButtonApi(false);
    dispatch(singlePatientToggle(false));
    dispatch(resetGetPatient());
    dispatch(resetSearchPatient());
    dispatch(resetPatientSearchAdv());
    dispatch(copyButton(false));
    dispatch(singlePatientToggle(false));
    dispatch(getPatientModified({}));
    dispatch(getUrl({ image: "" }));
    dispatch(getUrl({ photo: "" }));
    setName("");
    setDob("");
    setMobile("");
    setAddress1("");
    setAddress2("");
    setState("");
    setPincode("");
    setAlternatePhone("");
    setCity("");
    setpatientId("");
    setGender("");
    setSalutation("");
    setGuardian("");
    setRelationship("");
    setEmailid("");
    setIdno("");
    setIdtype("");
    setBloodgroup("");
    setAge("");
    setUniqueno("");
    setComments("");
    setAltSwitch(false);
  };

  const onCopySaveHandle = () => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!salutation) {
      toast.error("Please enter the salutation");
    } else if (!name) {
      toast.error("Please enter the name");
    } else if (!gender) {
      toast.error("Please enter the gender");
    } else if (!relationship) {
      toast.error("Please enter the relationship");
    } else if (!guardian) {
      toast.error("Please enter the guardian");
    } else if (!mobile) {
      toast.error("Please enter the mobile");
    } else if (mobile.length < 10) {
    } else if (!employeeId) {
      toast.error("Please enter the employee");
    } else if (mobile.length < 10) {
      toast.error("Mobile No. should be 10");
    } else if (!dob) {
      toast.error("Please enter the dob");
    }
    // else if (!bloodgroup) {
    //   toast.error("Please enter the blood group");
    // } else if (!emailid) {
    //   toast.error("Please enter the email id");
    // } else if (!emailid.includes("@")) {
    //   toast.error("Please enter the valid email id");
    // } else if (!idtype) {
    //   toast.error("Please enter the id type");
    // } else if (!idno) {
    //   toast.error("Please enter the id no");
    // }
    else if (emailid && !regex.test(emailid)) {
      toast.error("Please enter valid Email Id");
    } else if (!address1) {
      toast.error("Please enter the address1");
    }
    // else if (!address2) {
    //   toast.error("Please enter the address2");
    // }
    else if (!state) {
      toast.error("Please enter the state");
    } else if (!city) {
      toast.error("Please enter the city");
    } else if (pincode.length < 6) {
      toast("Pincode should be 6 digits");
    } else if (!pincode) {
      toast("Please enter the pincode");
    } else if (!country) {
      toast.error("Please enter the country");
    } else if (altSwitch && alternatePhone.length < 10) {
      toast.error("Alternate number should be 10");
    } else if (imageUrl) {
      dispatch(setPartner(copyApiData));
      setButtonApi(true);
      dispatch(singlePatientToggle(false));
      dispatch(resetGetPatient());
      dispatch(resetSearchPatient());
      dispatch(resetPatientSearchAdv());
      setName("");
      setDob("");
      setMobile("");
      setAddress1("");
      setAddress2("");
      setState("");
      setPincode("");
      setAlternatePhone("");
      setCity("");
      setpatientId("");
      setGender("");
      setSalutation("");
      setGuardian("");
      setRelationship("");
      setEmailid("");
      setIdno("");
      setIdtype("");
      setBloodgroup("");
      setAge("");
      setUniqueno("");
      setComments("");
    } else {
      dispatch(createPatient(apidata));
      dispatch(setPartner(copyApiData));
      setButtonApi(true);
      dispatch(singlePatientToggle(false));
      dispatch(resetGetPatient());
      dispatch(resetSearchPatient());
      dispatch(resetPatientSearchAdv());
      setName("");
      setDob("");
      setMobile("");
      setAddress1("");
      setAddress2("");
      setState("");
      setPincode("");
      setAlternatePhone("");
      setCity("");
      setpatientId("");
      setGender("");
      setSalutation("");
      setGuardian("");
      setRelationship("");
      setEmailid("");
      setIdno("");
      setIdtype("");
      setBloodgroup("");
      setAge("");
      setUniqueno("");
      setComments("");
    }
  };
  if (!error11) {
    setName("");
    setDob("");
    setMobile("");
    setAddress1("");
    setAddress2("");
    setState("");
    setPincode("");
    setAlternatePhone("");
    setCity("");
    setGender("");
    setSalutation("");
    setGuardian("");
    setRelationship("");
    setEmailid("");
    setIdno("");
    setIdtype("");
    setBloodgroup("");
    setAge("");
    setUniqueno("");
    setComments("");
    setAltSwitch("");
  }

  const UpdateHandler = () => {
    console.log("inside update handler");
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!salutation) {
      toast.error("Please enter the salutation");
    } else if (!name) {
      toast.error("Please enter the name");
    } else if (!gender) {
      toast.error("Please enter the gender");
    } else if (!relationship) {
      toast.error("Please enter the relationship");
    } else if (!guardian) {
      toast.error("Please enter the guardian");
    } else if (!mobile) {
      toast.error("Please enter the mobile");
    } else if (mobile.length < 10) {
    } else if (!employeeId) {
      toast.error("Please enter the employee");
    } else if (mobile.length < 10) {
      toast.error("Mobile No. should be 10");
    } else if (!dob) {
      toast.error("Please enter the dob");
    }
    // else if (!bloodgroup) {
    //   toast.error("Please enter the blood group");
    // } else if (!emailid) {
    //   toast.error("Please enter the email id");
    // }
    // else if (!emailid.includes('@')) {
    //     toast.error("Please enter the valid email id")
    // }
    else if (emailid && !regex.test(emailid)) {
      toast.error("Please enter valid Email Id");
    }
    //  else if (!idtype) {
    //   toast.error("Please enter the id type");
    // } else if (!idno) {
    //   toast.error("Please enter the id no");
    // }
    else if (!address1) {
      toast.error("Please enter the address1");
    }
    // else if (!address2) {
    //   toast.error("Please enter the address2");
    // }
    else if (!state) {
      toast.error("Please enter the state");
    } else if (!city) {
      toast.error("Please enter the city");
    } else if (!pincode) {
      toast.error("Please enter the pincode");
    } else if (pincode.length < 6) {
      toast.error("Pincode should be 6 digits");
    } else if (!country) {
      toast.error("Please enter the country");
    }
    // else if (altSwitch && alternatePhone.length < 10) {
    //     toast.error("Alternate number should be 10")
    // }
    else {
      dispatch(savePatient(savePatientApiData, patientId));
      setTimeout(() => {
        dispatch(getPatientAppointmet({ patientId }));
      }, 3000);
    }
  };

  return (
    <div className="add-new-patient-form">
      {copyToggle ? (
        <div className="partner-name">
          <p>Partner of: {getSinglePatientData?.name}</p>
        </div>
      ) : (
        <></>
      )}

      <div className="new-patient-name-div">
        <NumberButton className="numberButton">1</NumberButton>
        <div className="new-patient-name-details">
          <h6>NAME</h6>
        </div>
        <div className="all-input-fileds">
          <div>
            <SingleSelect
              onChange={onSalutationHandle}
              value={salutation}
              data={mrSelectData}
              placeholder="Select Mr/Mrs"
              className={"new-patient-1-div-multiselect1"}
            />
          </div>
          <div className="labelname-input-margin">
            <p>Name</p>
            <InputField
              data={data}
              value={name}
              type="text"
              maxLength={35}
              onChange={onNameHandle}
              id="name"
              placeholder="Name"
              className={"new-patient-1-div-input1"}
            />
          </div>
          <div className="labelname-select-gender-margin">
            <p>Gender</p>
            <SingleSelect
              data={genderdata}
              value={gender}
              onChange={onGenderHandle}
              id="gender"
              placeholder="Select Gender"
              className={"new-patient-1-div-multiselect2"}
            />
          </div>
        </div>
      </div>

      <div className="new-patient-name-div">
        <NumberButton className="numberButton-1">2</NumberButton>
        <div className="new-patient-name-details">
          <h6>GUARDIAN</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>Type</p>
            <SingleSelect
              data={guardianData}
              onChange={onGuardianRelaHandle}
              value={relationship}
              placeholder="Select Type"
              className={"new-patient-2-div-multiselect1"}
            />
          </div>
          <div className="labelname-select-margin">
            <p>Name</p>
            <InputField
              value={guardian}
              onChange={onGuardianHandle}
              maxLength={35}
              id="guardianname"
              data={data}
              placeholder="Name"
              className={"new-patient-2-div-input-field"}
            />
          </div>
        </div>
      </div>

      <div className="new-patient-name-div">
        <NumberButton className="numberButton-1">3</NumberButton>
        <div className="new-patient-name-details">
          <h6>PHONE</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>Mobile Number</p>
            <InputField
              onChange={onMobileHandle}
              value={mobile}
              disabled={singleToggles || copyToggle || patientToggle}
              maxLength="10"
              type="number"
              id="phonenumber"
              className={"new-patient-3-div-input-field1"}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
            />
          </div>
          <div className="labelname-select-margin">
            <p>Alternate Number</p>
            <InputField
              maxLength="10"
              type="number"
              onChange={onMobileAltHandle}
              value={alternatePhone}
              // disabled={!altSwitch}
              id="alternatenumber"
              placeholder=""
              className={"new-patient-3-div-input-field2"}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
            />
          </div>
          <div className="labelname-select-margin">
            <p>Alt Number Switch</p>
            <Switch
              value={altSwitch}
              checked={altSwitch}
              onChange={onSwitchHandle}
            />
          </div>
        </div>
      </div>

      <div className="new-patient-name-div">
        <NumberButton className="numberButton">4</NumberButton>
        <div className="new-patient-name-details">
          <h6>Employee ID</h6>
        </div>
        <div className="all-input-fileds">
          <div className="all-input-fileds">
            <div className="labelname-select-margin">
              <p>ID</p>
              <InputField
                placeholder=""
                value={employeeId}
                className={"new-patient-4-div-input-field2"}
                disabled="disabled"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="new-patient-name-div">
        <NumberButton className="numberButton">5</NumberButton>
        <div className="new-patient-name-details">
          <h6>DOB</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>Birth Date</p>
            <DatePicker
              format={"DD/MM/YYYY"}
              onChange={onDobHandle}
              value={dob ? moment(dob, "DD/MM/YYYY") : ""}
              className={"new-patient-4-div-input-field1"}
            />
          </div>
          <div className="all-input-fileds">
            <div className="labelname-select-margin-1">
              <p>Age</p>
              <InputField
                value={age}
                placeholder=""
                className={"new-patient-4-div-multiselect"}
              />
            </div>

            <div className="labelname-select-margin">
              <p>UHID Generate</p>
              <InputField
                placeholder=""
                value={uniqueno}
                className={"new-patient-4-div-input-field2"}
                disabled="disabled"
              />
            </div>
            <div className="labelname-select-margin">
              <p>Comments</p>
              <InputField
                onChange={onCommentsHandle}
                value={comments}
                className={"new-patient-4-div-input-field2"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="new-patient-name-div">
        <NumberButton className="numberButton-1">6</NumberButton>
        <div className="new-patient-name-details">
          <h6>Blood Group</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>Group</p>
            <SingleSelect
              data={bloodgroupdata}
              value={bloodgroup}
              onChange={onBloodHandle}
              id="patientbloodgroup"
              className={"new-patient-5-div-multiselect"}
            />
          </div>
          <div className="labelname-select-margin">
            <p>Email</p>
            <InputField
              onChange={onEmailHandle}
              value={emailid}
              id="patientemail"
              placeholder="Email"
              className={"new-patient-5-div-input-field"}
            />
          </div>
          <div></div>
        </div>
      </div>
      <div className="new-patient-name-div">
        <NumberButton className="numberButton-1">7</NumberButton>
        <div className="new-patient-name-details">
          <h6>ID</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>Type</p>
            <SingleSelect
              data={idData}
              value={idtype}
              id="patientidtype"
              onChange={onIdTypeHandle}
              className={"new-patient-6-div-multiselect"}
            />
          </div>
          <div className="labelname-select-margin">
            <p>ID No.</p>
            <InputField
              onChange={onIdHandle}
              value={idno}
              id="patientidno"
              placeholder="ID No"
              className={"new-patient-6-div-input-field"}
              maxLength={idInputLength}
            />
          </div>
        </div>
      </div>

      <div className="new-patient-name-div">
        <NumberButton className="numberButton-1">8</NumberButton>
        <div className="new-patient-name-details">
          <h6>Address 1</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>Line 1</p>
            <InputField
              onChange={onAddress1Handle}
              value={address1}
              data={data}
              placeholder=""
              className={"new-patient-7-input-field"}
            />
          </div>
        </div>
      </div>

      <div className="new-patient-name-div">
        <NumberButton className="numberButton-1">9</NumberButton>
        <div className="new-patient-name-details">
          <h6>Address 2</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>Line 2</p>
            <InputField
              onChange={onAddress2Handle}
              value={address2}
              data={data}
              placeholder=""
              className={"new-patient-7-input-field"}
            />
          </div>
        </div>
      </div>

      <div className="new-patient-name-div">
        <NumberButton className="numberButton">10</NumberButton>
        <div className="new-patient-name-details">
          <h6>STATE</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>State</p>
            <SingleSelect
              onChange={onStateHandle}
              value={state}
              id="patientstate"
              data={stateData}
              placeholder=""
              className={"new-patient-6-div-multiselect"}
            />
          </div>
          <div className="labelname-select-margin">
            <p>Town/City</p>
            <InputField
              onChange={onCityHandle}
              value={city}
              id="patientcity"
              placeholder=""
              className={"new-patient-6-div-multiselect"}
            />
          </div>
          <div className="labelname-select-margin">
            <p>Pincode</p>
            <InputField
              onChange={onPincodeHandle}
              type="number"
              value={pincode}
              id="citypincode"
              data={data}
              placeholder=""
              className={"new-patient-6-div-multiselect"}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
            />
          </div>
          <div></div>
        </div>
      </div>

      <div className="new-patient-name-div">
        <NumberButton className="numberButton-1">11</NumberButton>
        <div className="new-patient-name-details">
          <h6>COUNTRY</h6>
        </div>
        <div className="all-input-fileds">
          <div className="labelname-select-margin">
            <p>Pincode</p>
            <InputField
              value={91}
              id="phonenumber"
              className={"new-patient-3-div-input-field1"}
              disabled
            />
          </div>
          <div className="labelname-select-margin">
            <p>Name</p>
            <InputField
              id="alternatenumber"
              placeholder=""
              value={"India"}
              className={"new-patient-3-div-input-field2"}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="save-cancel-btns">
        {copyToggle ? (
          <Button onClick={onCopySaveHandle}>SAVE</Button>
        ) : patientToggle ? (
          <Button onClick={UpdateHandler} className={"new-patient-save-btn"}>
            Update
          </Button>
        ) : singleToggles ? (
          <Button
            onClick={oneEditSaveHandle}
            className={"new-patient-save-btn"}
          >
            SAVE
          </Button>
        ) : (
          <Button onClick={onSaveHandle} className={"new-patient-save-btn"}>
            SAVE
          </Button>
        )}
        <Button onClick={onClearHandle} className={"new-patient-cancel-btn"}>
          CLEAR
        </Button>
      </div>
      <Modal
        title={createPatientDetails?.name}
        visible={newPatientToggle}
        onCancel={handleCancel}
        width={550}
        className="appointment-modal-container"
      >
        <div className="appointment-modal">
          <div className="appointment-inp">
            <p className="calls-input-label">Select Location</p>
            <Input
              data={profileInfo}
              // onChange={onLocationHandle}
              // width={200}
              value={
                clinicName?.length === 0
                  ? doctorInfo?.locations?.[0]?.name
                  : clinicName[0]?.name
              }
              className="appointment-location-input"
              placeholder="Live Title"
            />
            {/* <SingleSelect
                            data={doctorInfo?.locations}
                            placeholder="Select Location"
                            onChange={onSelectLocationChange}
                            value={locationId}
                            className="appointment-location-select"
                        /> */}
          </div>
          <div className="appointment-inp appointment-date-gap">
            <p className="calls-input-label">Date & Time</p>
            <div className="appoint-inp">
              {/* <div className='appoint-walk-in'> */}
              <SingleSelect
                data={walkinData}
                width={56}
                placeholder="W"
                onChange={onWalkHandle}
                value={walkin}
              />
              {/* </div> */}
              {/* <DatePicker
                                // showTime={{
                                //     format: 'h:mm a',
                                //     format: 'h:mm a',
                                // }}
                                //     use12Hours format="YYYY-MM-DD h:mm a"
                                onChange={onDateTimeHandle}
                                value={scheduledTime !== "" ? moment(scheduledTime) : ""}
                                className="appointment-datepicker"
                            /> */}
              <DatePicker
                onChange={onDateTimeHandle}
                value={
                  scheduledTime == "Invalid date" ? null : moment(scheduledTime)
                }
                className="appointment-datepicker"
                defaultValue={moment(scheduledTime)}
                placeholder="Select Date"
              />
              {slotTimings && slotTimings.length ? (
                <SingleSelect
                  data={slotTimings}
                  onChange={handleTimeSlot}
                  value={timeslot}
                  className="slot_to"
                  placeholder="Select Time"
                />
              ) : (
                <SingleSelect
                  onChange={handleTimeSlot}
                  value={timeslot}
                  className="slot_to"
                  placeholder="Select Time"
                />
              )}
            </div>
          </div>
          <div className="appointment-inp">
            <p>NOTE</p>
            <textarea
              onChange={onNoteChange}
              value={note}
              className="appoint-textarea"
              placeholder="Appointment Notes"
              rows="1"
              cols="50"
            ></textarea>
          </div>
          <div className="calls-modal-btn">
            <Button
              onClick={handleOk}
              children="Save"
              className="calls-modal-btn1"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default NewPatient;
